<template>
  <div class="page adjust_width">

    <bo_retour name="bo_produits"></bo_retour>

    <title_cat :clickable="true" icon="edit" :link="{ name: 'bo_categorie_edit', params: { 'categorie_name': $route.params.categorie }}">Mes produits dans {{ $route.params.categorie }}</title_cat>
    
<!--    <router-link tag="div" :to="{ name: 'bo_categorie_edit', params: { 'categorie_name': $route.params.categorie }}" class="title">Produits dans <span>{{ $route.params.categorie }}</span></router-link>-->

    <router-link tag="div" :to="{ name: 'bo_produit_add', params: { categorie: $route.params.categorie } }" class="categorie plus shadow">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" /></svg>
      Ajouter un produit
    </router-link>
    
<!--    <router-link tag="button" :to="{ name: 'bo_produit_add', params: { categorie: $route.params.categorie } }" style="margin-bottom: 16px; margin-top: 0;" class="simple">Ajouter un produit</router-link>-->
    
    <produit_list v-for="(n, k) in produits_filter" :key="n.id" :data="n" @up="up(k)" @down="down(k)" @refresh="refresh"></produit_list>
    
  </div>
</template>

<script>
import produit_list from '@/components/bo/produit_list'
import Vuex from "vuex";
export default {
  name: "produits_in_catogory",
  data() {
    return {
      // produits: []
      produits_order: {},
      produits_filter: []
    }
  },
  components: {
    produit_list
  },
  computed: {
    ...Vuex.mapState({
      produits_all: state => state.boutique_bo.boutique.produits,
    }),
    ...Vuex.mapGetters({
      by_category: 'boutique_bo/by_category'
    })
    // produits () {
    //   return this.by_category(this.$route.params.categorie)
    // }
  },
  watch: {
    produits_all() {
      this.produits_filter = this.by_category(this.$route.params.categorie)
    }
  },
  created() {
    this.produits_filter = this.by_category(this.$route.params.categorie)
    // this.get_produits()
    this.save_orders()
  },
  methods: {
    refresh() {
      this.$store.dispatch("boutique_bo/sync");
    },
    save_orders() {
      let p = this.produits_filter
      for(let n in p) {
        this.produits_order[p[n].id] = parseInt(n)
      }
      this.$http.put('/bo/boutique/order_produits_in_category', { category: this.$route.params.categorie, orders: this.produits_order }).then(() => {
        this.$store.dispatch("boutique_bo/sync");
      })
    },

    up(i) {
      this.produits_filter = this.array_move(this.produits_filter, i, i - 1)
      this.$forceUpdate()
      // this.save_categories()
      this.save_orders()
    },
    down(i) {
      console.log('down init')
      if (this.produits_filter.length > i + 1) {
        this.produits_filter = this.array_move(this.produits_filter, i, i + 1)
        this.$forceUpdate()
        console.log('down ok')
        this.save_orders()
      }
    },
    array_move(arr, old_index, new_index) {
      console.log(arr, old_index, new_index)
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing purposes
    }
  }
}
</script>

<style scoped>

  .article { padding: 0 16px 0 0; }
  .categorie { background: #fff; display: flex; height: 60px; align-items: center; justify-content: space-between; font-size: 16px; padding: 0 14px; margin-bottom: 4px; border-radius: 2px; cursor: pointer }
  .categorie .name { font-weight: bold; color: #000 }
  .categorie .nb { font-size: 12px; color: var(--boColor-gris2) }
  .categorie .right svg { fill: var(--boColor-gris1) }
  .categorie.plus { display: flex; align-items: center; justify-content: center; height: 50px;  }
  .categorie.plus svg { fill: var(--boColor-gris2); margin-right: 6px; }


  @media only screen and (min-width: 800px) {
    .categorie.plus { display: inline-flex; margin-bottom: 8px; }
  }
</style>